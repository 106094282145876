@use "../config/" as *;
@forward "../shop/shopping-cart-common";
.waf-wp-shop-cart {
    @extend %px-4;
    .btn-site {
        width: max-content;
        padding-inline: 2rem;
        display: block;
        border-radius: var(--half-radius);
        height: 4.8rem;
        @extend %neutral-900;
        @extend %mx-auto;
        @extend %primary-300-bg;
        @extend %p-3-4;
        @extend %mb-6;
        .btn-text {
            @extend %font-14-pb;
            @extend %uppercase;
        }
    }
    .cart-product-head {
        @extend %d-none;
    }
    .article {
        &-list {
            @extend %mb-3;
        }
        &-item {
            border-bottom: .1rem solid var(--pure-white-500);
            @extend %mb-4;
            @extend %p-4;
        }
        &-wrap {
            @include flex-config(flex, null, space-between, center);
            @extend %flex-wrap;
        }
        &-content {
            @extend %w-70;
        }
        &-thumbnail {
            img {
                object-fit: cover;
            }
        }
    }
    .img-box {
        border-radius: 0;
    }
    .status-text {
        @extend %font-14-pm;
        @extend %neutral-400;
    }
    .cart-product-summary {
        @include border(1, neutral-1000);
        border-radius: .5rem;
        @extend %px-4;
        @extend %mb-8;
    }
    .coupon {
        &-wrapper {
            gap: 1.2rem;
            @extend %flex;
            .form-input {
                @include border(1, pure-white-400);
                height: 4.8rem;
                border-radius: var(--half-radius);
                @extend %w-60;
                @extend %p-2-4;
                @extend %transparent-bg;
                @extend %font-14-pr;
                @extend %neutral-500;
            }
        }
        &-status {
            @include flex-config(flex, null, space-between, center);
            border-radius: var(--half-radius);
            height: 4.8rem;
            box-shadow: 0px .3rem .6rem .1rem hsl(var(--hsl-neutral-900) / 25%);
            @extend %p-3-4;
            .coupon-text {
                @include flex-config(flex, null, null, center);
                &::before {
                    @include flex-config(flex, null, center, center);
                    margin-right: var(--space-4);
                    width: 2.8rem;
                    height: 2.8rem;
                }
            }
            &.valid {
                background-color: hsl(var(--hsl-success-800) / 10%);
                border: .1rem solid hsl(var(--hsl-success-800) / 15%);
                .btn-remove {
                    .btn-text {
                        @extend %font-14-pb;
                        @extend %error-800;
                        @extend %uppercase;
                    }
                }
                .coupon-text {
                    @extend %success-800;
                    &::before {
                        @include icon(check-drop, 10);
                        background-color: hsl(var(--hsl-success-800) / 20%);
                    }
                }
            }
            &.invalid {
                background-color: hsl(var(--hsl-error-800) / 10%);
                border: .1rem solid hsl(var(--hsl-error-800) / 15%);
                .btn-remove {
                    .btn-text {
                        @extend %font-14-pb;
                        @extend %error-800;
                        @extend %uppercase;
                    }
                }
                .coupon-text {
                    @extend %error-800;
                    &::before {
                        @include icon(close, 10);
                        background-color: hsl(var(--hsl-error-800) / 20%);
                    }
                }
            }
        }
        &-text {
            @extend %font-14-pb;
            &::before {
                margin-right: var(--space-4);
                @extend %rounded-radius;
                @extend %font-12-pb;
                @extend %p-2;
            }
        }
    }
    .product {
        &-coupon {
            .btn-apply {
                height: 4.8rem;
                flex: 1;
                border-radius: var(--half-radius);
                @extend %p-3-4;
                @extend %success-800-bg;
                .btn-text {
                    @extend %pure-white-900;
                    @extend %uppercase;
                    @extend %font-14-pm;
                }
            }
            .error-msg {
                font-weight: 700;
                @extend %error-900;
            }
        }
        &-checkout {
            @extend %flex;
            @extend %pt-5;
            .btn-checkout {
                border-radius: var(--half-radius);
                height: 4.8rem;
                flex: 1;
                @extend %primary-300-bg;
                @extend %p-3-4;
                @extend %mb-6;
                .btn-text {
                    @extend %font-14-pb;
                    @extend %uppercase;
                }
            }
        }
    }
    .checkbox-container {
        @extend %pt-5;
        @extend %gap-2;
        @extend %flex-n-fs;
        .text {
            line-height: 1.8rem;
            @extend %neutral-800;
            @extend %font-12-pr;
            &-bold {
                font-weight: 700;
            }
            .link-text {
                font-weight: 700;
                @extend %primary-700;
            }
        }
        input[type=checkbox] {
            @extend %d-none;
            &:checked ~ .checkmark {
                border: 0;
                @extend %primary-300-bg;
                &::after {
                    @include icon(checkmark, 10);
                    @extend %pure-white-900;
                }
            }
        }
        .checkmark {
            @include border(1, neutral-800);
            width: 1.3rem;
            height: 1.3rem;
            flex-shrink: 0;
            border-radius: .3rem;
            margin-top: .2rem;
            cursor: pointer;
            @extend %flex-c-c;
        }
    }
    .no-data-wrap {
        min-height: 70vh;
        @include flex-config(flex, null, center, center);
        @extend %w-100;
        .no-data {
            p {
                @extend %my-3;
                @extend %font-20-pb;
            }
        }
        .btn-site {
            border-radius: var(--half-radius);
            height: 4.8rem;
            @extend %d-block;
            @extend %text-center;
            @extend %primary-300-bg;
            @extend %p-3-4;
            @extend %mb-6;
            .btn-text {
                @extend %font-14-pb;
                @extend %capitalize;
                @extend %secondary-800;
            }
        }
    }
    .dropdown-wrap {
        @extend %pt-2;
        @extend %gap-3;
        @extend %flex-n-c;
        .waf-select-box {
            padding-inline: 0;
        }
        .dropdown-label {
            @extend %flex;
            @extend %neutral-800;
            @extend %capitalize;
            @extend %font-14-pm;
        }
        .select-dropdown {
            border: .1rem solid var(--neutral-1000);
            outline: none;
            height: 3rem;
            cursor: pointer;
            @extend %neutral-800;
            @extend %half-radius;
            @extend %p-1-2;
            @extend %w-100;
            @extend %transparent-bg;
            &.disabled {
                pointer-events: none;
                opacity: .5;
            }
        }
    }
}
@include mq(col-md) {
    .waf-wp-shop-cart {
        .cart-product-head {
            display: flex;
            gap: 5rem;
            align-items: center;
            height: 5rem;
            background-color: hsl(var(--hsl-neutral-100) / 0.2);
            padding: var(--space-5) var(--space-4);
            border-radius: .5rem;
        }
        .shopping-cart-wrapper {
            display: flex;
            gap: var(--space-4);
            flex-wrap: wrap;
        }
        .article {
            &-wrap {
                flex-wrap: nowrap;
                gap: 5rem;
                .price {
                    order: 2;
                    width: 15%;
                }
            }
            &-item {
                padding: var(--space-8)var(--space-4);
                margin-bottom: 0;
            }
            &-thumbnail {
                width: 20%;
                padding: var(--space-2) var(--space-3);
            }
        }
        .product-wrap {
            align-items: center;
            margin-bottom: 0;
            width: 55%;
        }
        .cart-product {
            &-info {
                width: 100%;
            }
            &-summary {
                width: 100%;
                height: max-content;
            }
        }
        .head-label {
            font-size: 1.6rem;
            font-weight: 700;
            text-transform: capitalize;
            &.head-label-product {
                width: 55%;
            }
            & {
                width: 15%;
            }
        }
        .product-count {
            order: 1;
            width: 15%;
        }
        .subtotal {
            order: 3;
            width: 15%;
        }
        .meta-item {
            .text {
                display: none;
            }
        }
        .dropdown-wrap {
            width: 70%;
            .waf-select-box {
                padding-inline: var(--space-2);
            }
            .select-dropdown {
                width: max-content;
                min-width: 14rem;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-wp-shop-cart {
        .cart-product {
            &-info {
                width: calc(70% - var(--space-4));
            }
            &-summary {
                width: 30%;
            }
        }
    }
}